<template>
    <OptionsModal
        v-if="selectedRule.id"
        :id="`optionsModal${selectedRule.id}`"
        :item="selectedRule"
        :include-publishing="inLocalDev"
        data-analytics-location="Rule Options Modal"
        :permissions="{
            update: hasVersioningPermission,
            update_status: hasVersioningPermission,
            delete: hasVersioningPermission,
            disable: canDisable || canManage,
        }"
        :saving="saving"
        static
        visible
        @hidden="handleHidden"
        @show="fetchHumanReadable(selectedRule)"
        @version:delete="handleDeleteVersion($event, 'delete')"
        @version:create-draft="handleCreateDraft({versionId: $event}, 'create_draft')"
        @version:publish="handlePublishVersion($event, 'publish')"
        @version:unschedule-publish="handleUnschedulePublishVersion($event, 'unschedule_publish')"
        @version:revert="handleRevertVersion($event, 'revert')"
        @version:unschedule-revert="handleUnscheduleRevertVersion($event, 'unschedule_revert')"
        @item:delete="handleDeleteRule($event, 'item:delete')"
        @item:obsolete="handleObsoleteRule($event, 'item:obsolete')"
        @item:unschedule-obsolete="handleUnscheduleObsoleteRule($event, 'item:unschedule_obsolete')"
        @item:disable="handleDisableRule($event, 'item:disable')"
        @item:enable="handleDisableRule($event, 'item:enable')"
    >
        <template #additional-info="{item}">
            <div class="form-group">
                <label>Name</label>

                <span
                    class="font-weight-normal"
                    data-cy="name"
                >
                    {{ item.title }}
                </span>
            </div>

            <div
                v-if="item.policyEffectiveDate"
            >
                <div class="form-group">
                    <label>Policy Effective Date</label>

                    <span
                        class="font-weight-normal"
                        data-cy="effectiveDate"
                    >
                        {{ formattedEffectiveDate }}
                    </span>
                </div>
            </div>

            <div class="form-group">
                <label>Rule Type</label>

                <span
                    class="font-weight-normal"
                    data-cy="ruleType"
                >
                    {{ tooltipWording.ruleTypes[item.ruleType] }}
                </span>
            </div>

            <template v-if="item.pageId">
                <div class="form-group">
                    <label>Rule Page Type</label>

                    <span
                        class="font-weight-normal"
                        data-cy="pageType"
                    >
                        {{ tooltipWording.pageTypes[item.pageType] }}
                    </span>
                </div>
            </template>
        </template>

        <template #version-content="{version}">
            <div class="row options-form">
                <div class="col-12 version-detail-group">
                    <div class="version-details col-12 pt-2">
                        <div class="col-12 p-0">
                            <div class="form-group">
                                <label>Transaction Type</label>

                                <span
                                    class="transactionType font-weight-normal"
                                    :data-cy="`transactionType${version.id}`"
                                >
                                    {{ transactionTypeDisplay(version) }}
                                </span>
                            </div>
                        </div>

                        <div class="col-12 p-0">
                            <div class="form-group">
                                <label>Description</label>

                                <span
                                    class="description text-break font-weight-normal"
                                    :data-cy="`description${version.id}`"
                                >
                                    {{ version.description }}
                                </span>
                            </div>
                        </div>

                        <div class="col-12 p-0">
                            <div class="form-group">
                                <label>Rule</label>

                                <div
                                    class="rule-plaintext font-weight-normal"
                                    :data-cy="`ruleText${version.id}`"
                                >
                                    <div v-if="humanReadableRuleText[version.id]">
                                        {{ humanReadableRuleText[version.id] }}
                                    </div>

                                    <div
                                        v-else
                                        class="d-flex pt-1"
                                    >
                                        <FontAwesomeIcon
                                            icon="spinner"
                                            pulse
                                        />

                                        <h5 class="ml-1">
                                            loading...
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 p-0">
                            <div class="form-group">
                                <label>Actions</label>

                                <span class="actions font-weight-normal">TODO</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #options-left="{version}">
            <RouterLink
                v-if="version.publishedAt === null && hasVersioningPermission"
                class="btn btn-primary mr-1"
                :to="{name: 'admin.rules.builder.edit', params: {policySystemId: currentPolicySystem.id, ruleId: $route.params.ruleId, versionId: version.id}, query: {...$route.query}}"
            >
                <FontAwesomeIcon
                    icon="pencil-alt"
                />
                Edit Version
            </RouterLink>

            <RouterLink
                class="btn btn-tertiary mr-1"
                :to="{name: 'admin.rules.builder.view', params: {policySystemId: currentPolicySystem.id, ruleId: $route.params.ruleId, versionId: version.id}, query: {...$route.query}}"
            >
                <FontAwesomeIcon
                    icon="eye"
                />
                View Rule
            </RouterLink>
        </template>
    </OptionsModal>
</template>

<script>
    import authMixin from '@imt/vue-toolbox/src/mixins/auth';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import utils from '@imt/vue-toolbox/src/utils';
    import OptionsModal from '@imt/vue-versioning/src/components/OptionsModal.vue';
    import versioningMixin from '@imt/vue-versioning/src/mixins/versioning';
    import dayjs from 'dayjs';
    import {mapActions, mapState} from 'vuex';

    import {tooltips} from '@/lang';
    import {disableMixin} from '@/mixins/disable';
    import permissionsMixin from '@/mixins/permissions';
    import {humanReadableRule} from '@/utilities';

    export default {
        name: 'BRRuleOptions',
        components: {
            OptionsModal,
        },
        mixins: [
            authMixin,
            disableMixin,
            permissionsMixin,
            toastsMixin,
            versioningMixin,
        ],
        data() {
            return {
                tooltipWording: tooltips.en,
                humanReadableRuleText: {},
                saving: [],
                fetchedRule: {},
            };
        },
        computed: {
            hasVersioningPermission() {
                return this.canManage || this.canVersionManage;
            },
            inLocalDev() {
                // TODO Uncomment original return line whenever workflow is figured out
                // return process.env.NODE_ENV !== 'production';
                return true;
            },
            formattedEffectiveDate() {
                return this.selectedRule.policyEffectiveDate ?
                    dayjs(this.selectedRule.policyEffectiveDate).format('MM/DD/YYYY') :
                    '';
            },
            selectedRule() {
                return this.rules.find(rule => rule.id === this.$route.params.ruleId) || this.fetchedRule; // eslint-disable-line eqeqeq
            },
            ...mapState([
                'currentPolicySystem',
                'rules',
            ]),
        },
        async created() {
            await this.init();
        },
        methods: {
            async init() {
                try {
                    if (!this.selectedRule.id) {
                        this.fetchedRule = await this.fetchRule(this.$route.params.ruleId) || {};
                    }
                } catch (error) /* istanbul ignore next */ {
                    await this.$router.push({name: 'admin.rules.list'});
                    this.error('This rule was not found or does not exist.', 'Rule Not Found');
                }
            },
            fetchHumanReadable(rule) {
                rule.versions.forEach(async version => {
                    let value = await humanReadableRule.createHumanReadable(version);
                    this.$set(this.humanReadableRuleText, version.id, value);
                });
            },
            transactionTypeDisplay(version) {
                return version.transactionType.map(type => {
                    return this.tooltipWording.transactionTypes[type];
                }).join(', ');
            },
            async handleHidden() {
                await this.$emit('fetchRules');
                await this.$router.push({name: 'admin.rules.list', query: {...this.$route.query}});
            },
            async handleCreateDraft($event, saveKey) {
                this.$ga.event('Rule Modal', 'Create Draft', 'action', {id: $event.versionId});
                this.saving.push(saveKey);

                try {
                    await this.createDraft({
                        version: this.selectedRule.versions.find(v => v.id === $event.versionId),
                        queryParams: this.$route.query
                    });

                    await this.handleSuccess('Successfully created draft!');
                } catch (error) {
                    await this.handleFailure(error, 'Failed to create draft!');
                }

                this.fetchHumanReadable(this.selectedRule);
                this.saving.splice(this.saving.indexOf(saveKey), 1);
            },
            async handlePublishVersion($event, saveKey) {
                this.$ga.event('Version Details', 'Publish', 'action');
                this.saving.push(saveKey);

                try {
                    let version = await this.publishVersion({
                            version: $event.version,
                            publishDate: $event.date,
                            queryParams: this.$route.query
                        }),
                        action = this.isPublished(version) ? 'published' : 'scheduled';

                    await this.handleSuccess(`Successfully ${action} version!`);
                } catch (error) {
                    await this.handleFailure(error, 'Failed to publish version!');
                }

                this.saving.splice(this.saving.indexOf(saveKey), 1);
            },
            async handleRevertVersion($event, saveKey) {
                this.$ga.event('Version Details', 'Revert', 'action');
                this.saving.push(saveKey);

                try {
                    let version = await this.revertVersion({
                            version: $event.version,
                            queryParams: this.$route.query,
                            revertDate: $event.date,
                        }),
                        text = this.isReverted(version) ? 'reverted version' : 'scheduled version to be reverted';

                    await this.handleSuccess(`Successfully ${text}!`);
                } catch (error) {
                    await this.handleFailure(error, 'Failed to revert version!');
                }

                this.saving.splice(this.saving.indexOf(saveKey), 1);
            },
            async handleUnschedulePublishVersion($event, saveKey) {
                this.$ga.event('Version Details', 'Unschedule Publish', 'action');
                this.saving.push(saveKey);

                try {
                    await this.unschedulePublish({
                        version: $event.version,
                        queryParams: this.$route.query
                    });

                    await this.handleSuccess('Successfully unscheduled version!');
                } catch (error) {
                    await this.handleFailure(error, 'Failed to unschedule version!');
                }

                this.saving.splice(this.saving.indexOf(saveKey), 1);
            },
            async handleUnscheduleRevertVersion($event, saveKey) {
                this.$ga.event('Version Details', 'Unschedule Revert', 'action');
                this.saving.push(saveKey);

                try {
                    await this.unscheduleRevert({
                        version: $event.version,
                        queryParams: this.$route.query
                    });

                    await this.handleSuccess('Successfully unscheduled revert!');
                } catch (error) {
                    await this.handleFailure(error, 'Failed to unschedule revert!');
                }

                this.saving.splice(this.saving.indexOf(saveKey), 1);
            },
            async handleDeleteVersion($event, saveKey) {
                this.$ga.event('Version Details', 'Delete Version', 'action');
                this.saving.push(saveKey);

                try {
                    await this.deleteVersion({
                        version: $event.version,
                        queryParams: this.$route.query
                    });

                    await this.handleSuccess('Successfully deleted version!');
                } catch (error) {
                    await this.handleFailure(error, 'Failed to delete version!');
                }

                this.saving.splice(this.saving.indexOf(saveKey), 1);
            },
            async handleObsoleteRule($event, saveKey) {
                this.$ga.event('Rule Modal', 'Obsolete', 'action');
                this.saving.push(saveKey);

                try {
                    await this.obsoleteRule({
                        ruleId: $event.item.id,
                        obsoleteDate: $event.date,
                        queryParams: this.$route.query
                    });

                    let text = this.isScheduledForObsolete({obsoleteAt: $event.date}) ?
                        'scheduled rule for obsolete' :
                        'obsoleted rule';

                    this.success(`Successfully ${text}!`);
                } catch (error) {
                    await this.handleFailure(error, 'Failed to obsolete rule!');
                }

                this.saving.splice(this.saving.indexOf(saveKey), 1);
            },
            async handleUnscheduleObsoleteRule($event, saveKey) {
                this.$ga.event('Rule Modal', 'Unschedule Obsolete', 'action');
                this.saving.push(saveKey);

                try {
                    await this.unscheduleObsolete({
                        ruleId: $event.item.id,
                        queryParams: this.$route.query
                    });

                    await this.handleSuccess('Successfully unscheduled obsolete!');
                } catch (error) {
                    await this.handleFailure(error, 'Failed to unschedule obsolete!');
                }

                this.saving.splice(this.saving.indexOf(saveKey), 1);
            },
            async handleDeleteRule($event, saveKey) {
                this.$ga.event('Rule Modal', 'Delete Rule', 'action');
                this.saving.push(saveKey);

                try {
                    await this.deleteRule({
                        ruleId: $event.item.id,
                        queryParams: this.$route.query
                    });

                    await this.handleSuccess('Successfully deleted rule!');
                } catch (error) {
                    await this.handleFailure(error, 'Failed to delete rule!');
                }

                this.saving.splice(this.saving.indexOf(saveKey), 1);
            },
            async handleDisableRule($event, saveKey) {
                this.$ga.event('Rule Modal', 'Disable Rule', 'action');
                this.saving.push(saveKey);

                try {
                    await this.disableRule({
                        rule: $event.item,
                        queryParams: this.$route.query
                    });

                    let action = $event.item.disabledAt ? 're-enabled' : 'disabled';
                    this.success(`Successfully ${action} rule!`);
                } catch (error) {
                    await this.handleFailure(error, 'Failed to disable/re-enable rule!');
                }

                this.saving.splice(this.saving.indexOf(saveKey), 1);
            },
            async handleSuccess(message) {
                this.success(message);
            },
            async handleFailure(error, message) {
                this.error(error?.response?.data?.errors ?
                    error.response.data.errors[0].detail :
                    message);

                utils.console.log(error);
            },
            ...mapActions([
                'createDraft',
                'deleteRule',
                'deleteVersion',
                'disableRule',
                'fetchRule',
                'obsoleteRule',
                'publishVersion',
                'revertVersion',
                'unscheduleObsolete',
                'unschedulePublish',
                'unscheduleRevert',
            ]),
        },
    };
</script>
